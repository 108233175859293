/* eslint-disable import/order */
import '@/@iconify/icons-bundle'
import App from '@/App.vue'
import ability from '@/plugins/casl/ability'
import i18n from '@/plugins/i18n'
import layoutsPlugin from '@/plugins/layouts'
import RollbarPlugin from '@/plugins/rollbar' // Path to your rollbar.js file
import vuetify from '@/plugins/vuetify'
import { loadFonts } from '@/plugins/webfontloader'
import router from '@/router'
import { abilitiesPlugin } from '@casl/vue'
import '@core/scss/template/index.scss'
import * as FullStory from '@fullstory/browser'
import '@styles/styles.scss'
import { createHead } from '@vueuse/head'
import { createPinia } from 'pinia'
import { createApp } from 'vue'

import Vue3Toastify from 'vue3-toastify'

loadFonts()

// Create vue app
const app = createApp(App)
const head = createHead()

// Fullstory
try {
  FullStory.init({
    orgId: 'o-1JDRKT-na1',
    debug: import.meta.env.VITE_NODE_ENV !== 'production',

    // devMode: process.env.VITE_NODE_ENV !== "production",
  })
  app.config.globalProperties.$FullStory = FullStory
}
catch {

}

// Close drawer when using broser history
window.addEventListener('popstate', event => {
  const currentRouteQuery = router.currentRoute.value.query

  if (currentRouteQuery && currentRouteQuery.id) {
    // openDrawer();
  }
  else if (Object.keys(currentRouteQuery).length) {
    router.replace({
      path: router.currentRoute.value.path,
    })
  }
})

// Use plugins
app.use(vuetify)
app.use(createPinia())
app.use(router)
app.use(layoutsPlugin)
app.use(i18n)
app.use(head)
app.use(abilitiesPlugin, ability, {
  useGlobalProperties: true,
})
app.use(Vue3Toastify, {
  autoClose: 3000,
  clearOnUrlChange: false,
} as ToastContainerOptions)

app.use(RollbarPlugin)

// Mount vue app
app.mount('#app')
