import { setupLayouts } from 'virtual:generated-layouts'
import { createRouter, createWebHistory } from 'vue-router'
import banking from './banking'
import donations from './donations'
import donors from './donors'
import giving from './giving'
import givingPublic from './givingPublic'
import onboarding from './onboarding'
import operations from './operations'
import ribbon from './ribbon'
import settings from './settings'
import sponsor from './sponsor'
import { isUserLoggedIn } from './utils'
import routes from '~pages'
import { verifyCharityUrlState } from '@core/utils'
import { useUserStore } from '@/stores/user'
import { canNavigate } from '@/@layouts/plugins/casl'

import { useRouteStore } from '@/stores/routeStore'

const router = createRouter({
  history: createWebHistory('/'),
  routes: [
    // ℹ️ We are redirecting to different pages based on role.
    // NOTE: Role is just for UI purposes. ACL is based on abilities.
    {
      path: '/',
      redirect: to => {
        if (to.query.to)
          return { path: to.query.to.toString(), query: undefined }

        return { name: 'dashboard-interstitial', query: to.query }
      },
    },
    ...setupLayouts([
      ...routes,
      {
        path: '/charity/:charity_id',
        name: 'charity-home',
        component: () => import('@/pages/home/charity.vue'),
        meta: {
          title: 'Dashboard',
        },
      },
      {
        path: '/charity/:charity_id/final-step',
        name: 'final-step',
        component: () => import('@/pages/home/finalStep.vue'),
        meta: {
          layout: 'blank',
          title: 'Final Step',
        },
        props: true,
      },
      {
        path: '/invitation/:uuid',
        name: 'auth-invitation',
        component: () => import('@/pages/invitation.vue'),
        meta: {
          layout: 'blank',
          resource: 'Public',
          redirectIfLoggedIn: true,
        },
        props: true,
      },
      {
        path: '/apply/:code',
        name: 'public-invitation',
        component: () => import('@/pages/invitation.vue'),
        meta: {
          layout: 'blank',
          resource: 'Public',
          redirectIfLoggedIn: true,
        },
        props: true,
      },
      {
        path: '/reset-password/:token',
        name: 'auth-reset-password',
        component: () => import('@/pages/reset-password.vue'),
        meta: {
          layout: 'blank',
          resource: 'Public',
          redirectIfLoggedIn: true,
        },
        props: true,
      },
      {
        path: '/receipt-upload/:uuid',
        name: 'receipt-upload',
        component: () => import('@/pages/ReceiptUpload.vue'),
        meta: {
          layout: 'blank',
          resource: 'Public',
          redirectIfLoggedIn: true,
        },
        props: true,
      },
      {
        path: '/sponsor-onboarding/:onboarding_id',
        name: 'sponsor-onboarding',
        component: () => import('@/pages/sponsoronboarding.vue'),
        props: true,
      },
      {
        path: '/downloads/receipts/:uuid',
        name: 'donation-receipt-redirect', // redirects donation receipts from emails
        component: { template: '<div></div>' },
        beforeEnter(to) {
          const uuid = to.params.uuid
          const serverUrl = `${import.meta.env.VITE_SERVER_URL}/downloads/receipts/${uuid}`

          window.location.href = serverUrl
        },
      },
      {
        path: '/links/:uuid',
        name: 'short-link-redirect', // redirects donation receipts from emails
        component: { template: '<div></div>' },
        meta: {
          layout: 'blank',
          resource: 'Public',
        },
        beforeEnter(to) {
          const uuid = to.params.uuid
          const serverUrl = `${import.meta.env.VITE_SERVER_URL}/links/${uuid}`

          window.location.href = serverUrl
        },
      },
      ...sponsor,
      ...giving,
      ...givingPublic,
      ...donations,
      ...donors,
      ...banking,
      ...onboarding,
      ...operations,
      ...ribbon,
      ...settings,
    ]),
  ],
}) // Adjust the path based on your file structure

router.beforeEach((to, from, next) => {
  const routeStore = useRouteStore()

  routeStore.setOriginatingRouteName(from.name)
  next()
})

// Docs: https://router.vuejs.org/guide/advanced/navigation-guards.html#global-before-guards
router.beforeEach(async to => {
  if (window.location.protocol !== 'https:' && import.meta.env.VITE_APP_URL.startsWith('https:') && import.meta.env.VITE_NODE_ENV !== 'local')
    window.location.replace(import.meta.env.VITE_APP_URL + to.fullPath)

  // If redirecting using legacy route starting with /r, will strip that part and redirect the user
  if (to.path.startsWith('/r/') || to.path.endsWith('/r')) {
    let path = to.path.replace('/r', '')

    // redirect from stripe callback, can be updated on the server once beta is over and this client is the only one that users use
    if (path.endsWith('/dashboard'))
      path = path.replace('/dashboard', '')

    return { path, query: to.query }
  }

  verifyCharityUrlState(to)

  if (isUserLoggedIn()) {
    if (to.name === 'receipt-upload')
      return

    const user = useUserStore()
    if (!user.data.uuid)
      await user.get()

    if (to.meta.redirectIfLoggedIn)
      return { name: 'dashboard-interstitial' }

    // for not-authorized
    if (!canNavigate(to))
      return { path: '/' }
  }
  else if (!to.meta.redirectIfLoggedIn && (!to?.meta?.resource || (typeof to?.meta?.resource === 'string' && to.meta.resource.toLowerCase() !== 'public'))) {
    let redirectPath: string | undefined = to.fullPath
    if (to.name === 'dashboard-interstitial' || to.name === 'index')
      redirectPath = undefined

    return { name: 'login', query: { to: redirectPath } }
  }
})

export default router
