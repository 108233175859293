const onboarding = [
  {
    path: '/welcome',
    name: 'dashboard-welcome',

    component: () => import('@/pages/onboarding/Welcome.vue'),
    meta: {
    },
    props: true,
  },
  {
    path: '/application/:application_id/new',
    name: 'onboarding-charity-name',

    component: () => import('@/pages/onboarding/CharityName.vue'),
    meta: {
      step: null,
    },
    props: true,
  },
  {
    path: '/application/:application_id/charity-mission',
    name: 'onboarding-charity-mission',

    component: () => import('@/pages/onboarding/CharityMission.vue'),
    meta: {
      step: null,
    },
    props: true,
  },
  {
    path: '/application/:application_id/charity-category',
    name: 'onboarding-charity-category',

    component: () => import('@/pages/onboarding/CharityCategory.vue'),
    meta: {
      step: null,
    },
    props: true,
  },
  {
    path: '/application/:application_id/charity-location',
    name: 'onboarding-charity-location',

    component: () => import('@/pages/onboarding/CharityLocation.vue'),
    meta: {
      step: null,
    },
    props: true,
  },
  {
    path: '/application/:application_id/charity-projections',
    name: 'onboarding-charity-projections',

    component: () => import('@/pages/onboarding/CharityProjections.vue'),
    meta: {
      step: null,
    },
    props: true,
  },
  {
    path: '/application/:application_id/charity-kyc',
    name: 'onboarding-charity-kyc',

    component: () => import('@/pages/onboarding/CharityKYC.vue'),
    meta: {
      step: null,
    },
    props: true,
  },
  {
    path: '/application/:application_id/charity-veriff',
    name: 'onboarding-charity-veriff',

    component: () => import('@/pages/onboarding/CharityVeriff.vue'),
    meta: {
      step: null,
    },
    props: true,
  },
  {
    path: '/application/:application_id/review',
    name: 'onboarding-charity-review',

    component: () => import('@/pages/onboarding/ApplicationReview.vue'),
    meta: {
      step: null,
    },
    props: true,
  },
  {
    path: '/application/:application_id/home',
    name: 'application-home',

    component: () => import('@/pages/onboarding/ApplicationHome.vue'),
    meta: {
      step: null,
    },
    props: true,
  },
  {
    path: '/application/:application_id/decision/:decision_id/contract/:contract_id',
    name: 'application-contract',

    component: () => import('@/pages/onboarding/ApplicationContract.vue'),
    meta: {
      step: null,
    },
    props: true,
  },
  {
    path: '/application/:application_id/sponsor/:sponsor_id',
    name: 'onboarding-sponsor-view',

    component: () => import('@/pages/sponsor/ViewSponsor.vue'),
    meta: {
      step: null,
    },
    props: true,
  },
]

const totalSteps = onboarding.length - 1
let step = 1

onboarding.map((route: any) => {
  if (route.meta && Object.keys(route.meta).includes('step')) {
    route.meta.totalSteps = totalSteps
    route.meta.step = step
    step++
  }
})
export default onboarding
