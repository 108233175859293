<script lang="ts" setup>
import { useSkins } from '@core/composable/useSkins'
import { useThemeConfig } from '@core/composable/useThemeConfig'
import { useCharityStore } from '@/stores/charity'
import { useEntityStore } from '@/stores/entity'
import { useSponsorStore } from '@/stores/sponsor'

// @layouts plugin
import { AppContentLayoutNav } from '@layouts/enums'

const DefaultLayoutWithHorizontalNav = defineAsyncComponent({
  loader: () => import('./components/DefaultLayoutWithHorizontalNav.vue'),
  delay: 200,
  timeout: 3000,
})

const DefaultLayoutWithVerticalNav = defineAsyncComponent({
  loader: () => import('./components/DefaultLayoutWithVerticalNav.vue'),
  delay: 200,
  timeout: 3000,
})

const { width: windowWidth } = useWindowSize()
const { appContentLayoutNav, switchToVerticalNavOnLtOverlayNavBreakpoint } = useThemeConfig()

// ℹ️ This will switch to vertical nav when define breakpoint is reached when in horizontal nav layout
// Remove below composable usage if you are not using horizontal nav layout in your app
switchToVerticalNavOnLtOverlayNavBreakpoint(windowWidth)

const { layoutAttrs, injectSkinClasses } = useSkins()

injectSkinClasses()

const charityDetails = useCharityStore()
const sponsorDetails = useSponsorStore()
const entity = useEntityStore()
const route = useRoute()
const router = useRouter()

const isRibbon = inject('isRibbon', ref(false))
const isSponsor = inject('isSponsor', ref(false))

const entityName = computed(() => {
  if (route.params.charity_id) {
    if (charityDetails)
      return charityDetails.data?.name
  }
  else if (route.params.sponsor_id) {
    if (sponsorDetails)
      return sponsorDetails.data?.name
  }

  return ''
})

const charityCompletedAt = computed(() => {
  return !!charityDetails?.data?.completed_at
})

const onRouteBackToSponsor = () => {
  new Promise(resolve => {
    if (isSponsor.value) {
      entity.set(charityDetails.data?.sponsor)
      sponsorDetails.get(charityDetails.data?.sponsor?.uuid)
    }
    else if (isRibbon.value) {
      entity.set({ ribbon: true })
    }
    resolve()
  })
    .then(() => {
      if (isSponsor.value) {
        router.push({
          name: 'sponsor-program-details',
          params: {
            sponsor_id: charityDetails.data?.sponsor?.uuid,
            charity_id: charityDetails.data?.uuid,
          },
        })
      }
      else if (isRibbon.value) {
        if (route.params.sponsor_id) {
          router.push({
            name: 'ribbon-sponsor-details',
            params: {
              sponsor_id: sponsorDetails.data?.uuid,
            },
          })
        }
        else {
          router.push({
            name: 'ribbon-program-details',
            params: {
              charity_id: charityDetails.data?.uuid,
            },
          })
        }
      }
    })
    .finally(() => {
      isSponsor.value = false
      isRibbon.value = false
    })
}

watch(entityName, newValue => {
  if (newValue === '') {
    isSponsor.value = false
    isRibbon.value = false
  }
})

if (entityName.value === '') {
  isSponsor.value = false
  isRibbon.value = false
}
</script>

<template>
  <VSystemBar
    v-if="(isRibbon || isSponsor)"
    class="app-system-bar"
    app
    height="50px"
    style="z-index: 1002;"
    :elevation="0"
  >
    <div class="sponsor-alert-bar">
      <VCol class="text-left pa-0">
        <div class="mb-0 text-body-2">
          <span>You are logged in as: </span>
          <strong>{{ entityName }}</strong>
        </div>
      </VCol>
      <VCol class="pa-0">
        <VBtn
          color="white"
          class="text-primary"
          style=" height: 32px;padding: 0 12px;"
          @click="onRouteBackToSponsor"
        >
          Back to My Account
        </VBtn>
      </VCol>
    </div>
  </VSystemBar>

  <VSystemBar
    v-if="((isRibbon || isSponsor) && charityCompletedAt)"
    class="app-system-bar"
    app
    height="50px"
    style="z-index: 1002;"
    color="error"
    variant="tonal"
    :elevation="0"
  >
    <div
      class="sponsor-alert-bar  text-body-2"
      style="color: white !important"
    >
      This program is
      ended. No new activity is permitted, but it is viewable for historical
      data purposes.
    </div>
  </VSystemBar>

  <template v-if="appContentLayoutNav === AppContentLayoutNav.Vertical">
    <DefaultLayoutWithVerticalNav v-bind="layoutAttrs" />
  </template>
  <template v-else>
    <DefaultLayoutWithHorizontalNav v-bind="layoutAttrs" />
  </template>
</template>

<style lang="scss">
// As we are using `layouts` plugin we need its styles to be imported
@use "@layouts/styles/default-layout";
@import "@styles/variables/_variables";

.app-system-bar {
  position: static !important;
  flex: 0 !important;
  background: rgba($primary-shade--dark, 0.75) !important;
  block-size: auto !important;
  &--error {
    background: rgba($error, 0.9) !important;
    text-align: left !important;
  }
}

.sponsor-alert-bar {
  display: flex;
  align-items: center;
  block-size: 50px;
  inline-size: 100%;
  margin-block: 0;
  margin-inline: auto;
  max-inline-size: 1440px;
  padding-block: 0 !important;
  padding-inline: 1.5rem !important;

  .v-row {
    line-height: 1.3;
  }
}
</style>
