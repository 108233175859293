import { sanitize } from '@/@core/utils/formatters'
import axios from '@axios'
import { analytics } from '@core/utils/tracker'

interface LoginRequest {
  email: string
  password: string
}

export const useAuthStore = () => {
  const router = useRouter()
  const route = useRoute()

  return defineStore('auth', {
    state: () => ({
      error: '',
      isFetching: false,
      email: '',
      token: localStorage.getItem('accessToken') || '',
      tfa: null as any,
    }),
    getters: {
      isLoggedIn: state => !!state.token,
    },
    actions: {
      login(creds: LoginRequest) {
        this.setFetching(true)

        analytics.track('login_attempted', {
          email: creds.email,
        })

        return new Promise((resolve, reject) => {
          axios
            .post('/login', creds)
            .then(({ data }) => {
              analytics.track('login', {
                email: creds.email,
              })
              analytics.identify(data.id)
              this.tfa = data
              this.email = creds.email
              this.setFetching(false)

              // Redirect to `to` query if exist or redirect to index route
              router.push({
                name: 'verify',
                query: route.query,
              })

              resolve(data)
            })
            .catch(({ response }) => {
              this.setFetching(false)
              this.error = sanitize(response?.data?.message || response)
              reject(sanitize(response?.data?.message || response))
            })
        })
      },
      verifyTFA(verification_code: string) {
        this.setFetching(true)

        return new Promise((resolve, reject) => {
          axios
            .post('/verify-phone', {
              verification_token: this.tfa.token,
              verification_code,
            })
            .then(({ data }) => {
              analytics.track('2fa success')
              analytics.identify(data.id)

              // Store token as well as set it for axios use
              localStorage.setItem('accessToken', data.token)
              this.token = data.token
              this.setFetching(false)
              router.push({
                path: '/',
                query: route.query,
              })
            })
            .catch(({ response }) => {
              this.setFetching(false)
              this.error = response?.data?.message || response
              reject(response?.data?.message || response)
            })
        })
      },
      resendPhoneTFA() {
        this.setFetching(true)

        return new Promise((resolve, reject) => {
          if (this.tfa) {
            axios
              .post(`${import.meta.env.VITE_SERVER_URL}/send-phone-code`, {
                verification_token: this.tfa.token,
                email: this.email,
              })
              .then(() => {
                analytics.track('resend 2fa')
                this.setFetching(false)
                resolve({})
              })
              .catch(({ response }) => {
                this.setFetching(false)
                this.error = response?.data?.message || response
                reject(response?.data?.message || response)
              })
          }
        })
      },
      resendEmailTFA() {
        this.setFetching(true)

        return new Promise((resolve, reject) => {
          if (this.tfa) {
            axios
              .post(`${import.meta.env.VITE_SERVER_URL}/send-email-code`, {
                verification_token: this.tfa.token,
                email: this.email,
              })
              .then(() => {
                analytics.track('resend 2fa')
                this.setFetching(false)
                resolve({})
              })
              .catch(({ response }) => {
                this.setFetching(false)
                this.error = response?.data?.message || response
                reject(response?.data?.message || response)
              })
          }
        })
      },
      logout() {
        this.tfa = null
        this.setFetching(false)

        // Remove local store
        localStorage.removeItem('accessToken')
        localStorage.removeItem('charity')
        localStorage.removeItem('sponsor')
        localStorage.removeItem('application')
        localStorage.removeItem('isRibbon')
        localStorage.removeItem('isSponsor')

        // Redirect to login page
        router.push('/login')
          .then(() => {
            // ℹ️ We had to remove abilities in then block because if we don't nav menu items mutation is visible while redirecting user to login page
            // Remove "userAbilities" from localStorage
            // localStorage.removeItem('userAbilities')

            // Reset ability to initial ability
            // ability.update(initialAbility)
          })
      },
      forgotPassword(email: string) {
        return new Promise((resolve, reject) => {
          axios
            .post(`${import.meta.env.VITE_SERVER_URL}/forgot-password`, { email })
            .then(({ data }) => resolve(data))
            .catch(({ response }) => reject(response))
        })
      },
      resetPassword(body: any) {
        this.setFetching(true)

        return new Promise((resolve, reject) => {
          axios
            .post(`${import.meta.env.VITE_SERVER_URL}/reset-password`, body)
            .then(data => {
              this.setFetching(false)
              resolve(data)
            })
            .catch(({ response }) => {
              this.setFetching(false)
              this.error = response
              reject(response)
            })
        })
      },
      register(creds: LoginRequest) {
        // TODO add analytics
        analytics.track('register_attempted', {
          email: creds.email,
        })
        this.setFetching(true)

        return new Promise((resolve, reject) => {
          axios
            .post(`${import.meta.env.VITE_SERVER_URL}/register`, creds)
            .then(({ data }) => {
              analytics.track('register', {
                email: creds.email,
              })
              analytics.identify(data.id)
              this.tfa = data
              this.setFetching(false)
              resolve(data)
            })
            .catch(({ response }) => {
              this.setFetching(false)
              this.error = response?.data?.message || response
              reject(response?.data?.message || response)
            })
        })
      },
      setFetching(value: boolean) {
        this.isFetching = value
        if (value === true)
          this.error = null
      },
    },
  })()
}
