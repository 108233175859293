const ops = [
  // {
  //   path: '/charity/:charity_id/ops/board',
  //   name: 'ops-board',
  //   component: () => import('@/views/operations/board.vue'),
  //   meta: {
  //     layout: 'content',
  //     resource: 'charity',
  //     permissions: ['view_advisory_board'],
  //   },
  //   props: true,
  // },
  {
    path: '/charity/:charity_id/ops/documents',
    name: 'ops-documents',
    component: () => import('@/pages/operations/documents.vue'),
    meta: {
      title: 'Documents',
      permissions: ['view_documents'],
    },
    props: true,
  },

  // {
  //   path: '/charity/:charity_id/ops/documents/upload',
  //   name: 'ops-upload-document',
  //   component: () => import('@/views/operations/UploadForm'),
  //   meta: {
  //     layout: 'content',
  //     resource: 'charity',
  //     permissions: ['view_documents'],
  //   },
  //   props: true,
  // },
  // {
  //   path: '/charity/:charity_id/ops/templates',
  //   name: 'ops-legal-templates',
  //   component: () => import('@/views/operations/legalTemplates'),
  //   meta: {
  //     layout: 'content',
  //     permissions: ['view_templates'],
  //   },
  //   props: true,
  // },
  // {
  //   path: '/charity/:charity_id/ops/receipt-marriage',
  //   name: 'ops-receipts',
  //   component: () => import('@/views/operations/receipts'),
  //   meta: {
  //     layout: 'content',
  //     resource: 'charity',
  //   },
  //   props: true,
  // },
  // {
  //   path: '/charity/:charity_id/ops/receipt-upload',
  //   name: 'ops-receipt-upload',
  //   component: () => import('@/views/operations/ReceiptUpload'),
  //   meta: {
  //     layout: 'content',
  //     resource: 'charity',
  //   },
  //   props: true,
  // },
  {
    path: '/charity/:charity_id/ops/budgets',
    name: 'ops-budgets',
    component: () => import('@/pages/banking/budgets.vue'),
    meta: {
      title: 'Budgets',
      resource: 'charity',
      permissions: ['view_budgets'],
    },
    props: true,
  },
  {
    path: '/charity/:charity_id/ops/grants',
    name: 'ops-grants',
    component: () => import('@/pages/banking/grants.vue'),
    meta: {
      title: 'Grants',
      resource: 'charity',
      permissions: ['view_grants'],
    },
    props: true,
  },

  {
    path: '/sponsor/:sponsor_id/ops/budgets',
    name: 'sponsor-ops-budgets',
    component: () => import('@/pages/banking/budgets.vue'),
    meta: {
      title: 'Budgets',
      resource: 'sponsor',
      permissions: ['view_budgets'],
    },
    props: true,
  },
  {
    path: '/sponsor/:sponsor_id/ops/documents',
    name: 'sponsor-ops-documents',
    component: () => import('@/pages/operations/SponsorDocuments.vue'),
    meta: {
      title: 'Documents',
      resource: 'sponsor',
      permissions: ['view_documents'],
    },
    props: true,
  },
  {
    path: '/sponsor/:sponsor_id/ops/documents/contracts/edit/:contract_id',
    name: 'sponsor-ops-documents-contract-edit',
    component: () => import('@/pages/operations/SponsorEditContractTemplate.vue'),
    meta: {
      title: 'Contracts',
      resource: 'sponsor',
      permissions: ['view_documents'],
    },
    props: true,
  },
]

export default ops
