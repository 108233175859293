import { defineStore } from 'pinia'

export const useRouteStore = defineStore('route', {
  state: () => ({
    originatingRouteName: '',
  }),
  actions: {
    setOriginatingRouteName(name) {
      this.originatingRouteName = name
    },
  },
})

interface TransactionCategoryPayload {
  name: string
  general_ledger_id: string
  type: 'expense' | 'income'
  'apply-to-all': boolean
  'selected-programs': string[]
}

type TransactionCategoryRequest = TransactionCategoryPayload[]
