export const useEntityStore = () => {
  const route = useRoute()

  return defineStore('entity', {
    state: () => ({
      data: null as any,
    }),
    getters: {
      type: state => {
        if (!state.data)
          return null

        if (!route.path)
          return null

        const uuid = state.data?.uuid ?? ''
        if (route.path.startsWith('/ribbon/') && state.data.ribbon)
          return 'ribbon'

        else if (route.path.startsWith('/sponsor/') || uuid.includes('spon_'))
          return 'sponsor'

        else if (
          route.path.startsWith('/application/')
      || uuid.includes('chap_')
        )
          return 'application'

        else if (route.path.startsWith('/charity/') || uuid.includes('char_'))
          return 'charity'

        else if (route.path.includes('char'))
          return 'charity'

        return null
      },
    },
    actions: {
      set(entity: any) {
        this.data = entity
      },
      reset() {
        this.data = null
      },
    },
  })()
}
