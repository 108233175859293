import Rollbar from 'rollbar'

const rollbar = new Rollbar({
  accessToken: import.meta.env.VITE_ROLLBAR_KEY,
  captureUncaught: true,
  captureUnhandledRejections: false,
  enabled: true,
  environment: import.meta.env.VITE_ROLLBAR_ENVIRONMENT,
  payload: {
    // Track your events to a specific version of code for better visibility into version health
    // code_version: "" + `${HEROKU_RELEASE_VERSION}`,
    // Add custom data to your events by adding custom key/value pairs like the one below
    // custom_data: 'foo'
  },
})

export default {
  install(app) {
    app.config.errorHandler = (error, vm, info) => {
      if (import.meta.env.VITE_NODE_ENV === 'production')
        rollbar.error(error, { vueComponent: vm, info })

      if (app.config.devtools)
        console.error(error)
    }
    app.provide('rollbar', rollbar)
  },
}
