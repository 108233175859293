const ribbon = [
  {
    path: '/ribbon/dashboard',
    name: 'ribbon-home',
    component: () => import('@/pages/home/ribbon.vue'),
    meta: {
      title: 'Dashboard',
      resource: 'Ribbon',
    },
  },
  {
    path: '/ribbon/integrations',
    name: 'ribbon-integrations',
    component: () => import('@/pages/ribbon/IntegrationList.vue'),
    meta: {
      title: 'Ribbon Integrations',
      resource: 'Ribbon',
    },
  },
  {
    path: '/ribbon/sponsors',
    name: 'ribbon-sponsors',
    component: () => import('@/pages/ribbon/SponsorList.vue'),
    meta: {
      title: 'Ribbon Sponsors',
      resource: 'Ribbon',
    },
  },
  {
    path: '/ribbon/sponsor/:sponsor_id',
    name: 'ribbon-sponsor-details',
    component: () =>
      import('@/pages/settings/sponsor-settings/AccountSettings.vue'),
    meta: {
      title: 'Sponsor Settings',
      resource: 'Ribbon',
    },
  },
  {
    path: '/ribbon/programs',
    name: 'ribbon-programs',
    component: () => import('@/pages/ribbon/ProgramList.vue'),
    meta: {
      title: 'Ribbon Programs',
      resource: 'Ribbon',
    },
  },
  {
    path: '/ribbon/programs/:charity_id',
    name: 'ribbon-program-details',
    component: () =>
      import('@/pages/settings/account-settings/AccountSettings.vue'),
    meta: {
      title: 'Account Settings',
      resource: 'Ribbon',
    },
  },
  {
    path: '/ribbon/applications',
    name: 'ribbon-applications',
    component: () => import('@/pages/ribbon/ApplicationList.vue'),
    meta: {
      title: 'Applications',
      resource: 'Ribbon',
    },
  },
  {
    path: '/ribbon/banking',
    name: 'ribbon-banking',
    component: () => import('@/pages/ribbon/SponsorBanking.vue'),
    meta: {
      title: 'Banking',
      resource: 'Ribbon',
    },
  },
  {
    path: '/ribbon/banking-tools',
    name: 'ribbon-banking-tools',
    component: () => import('@/pages/ribbon/RibbonBankingTools.vue'),
    meta: {
      title: 'Banking Tools',
      resource: 'Ribbon',
    },
  },
  {
    path: '/ribbon/permissions',
    name: 'ribbon-permissions',
    component: () => import('@/pages/ribbon/CharityPermissions.vue'),
    meta: {
      title: 'Charity Permissions',
      resource: 'Ribbon',
    },
  },
  {
    path: '/ribbon/check-management',
    name: 'ribbon-check-management',
    component: () => import('@/pages/ribbon/check-management/check-management.vue'),
    meta: {
      title: 'Check Management',
      resource: 'Ribbon',
    },
  },
]

export default ribbon
