<script lang="ts" setup>
import { toast } from 'vue3-toastify'
import { useBankingCardStore } from '@/stores/card'
import { useUserStore } from '@/stores/user'

const user = useUserStore()
const card = useBankingCardStore()

const showDialog = ref(user.currentCardholder?.uuid && !user.currentCardholder.terms_accepted_at)
const loading = ref(false)

const onAcceptTerms = () => {
  loading.value = true
  card.acceptTerms({ ch_uuid: user.currentCardholder.uuid })
    .then(() => toast.success('Thank you for accepting the Authorized User Terms.'))
    .catch(toast.error)
    .finally(() =>
      loading.value = false)
}

watch(() => user.currentCardholder, () => {
  showDialog.value
          = user.currentCardholder?.uuid && !user.currentCardholder.terms_accepted_at
})
</script>

<template>
  <div class="text-center">
    <VDialog
      v-model="showDialog"
      fullscreen
      transition="fade-transition"
    >
      <VContainer
        fluid
        :style="{
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          height: '100vh',
          width: '100vw',
        }"
      >
        <VRow
          align="center"
          justify="center"
          class="mx-0"
          style="height: 100vh"
        >
          <VSpacer />
          <VCol
            align="center"
            justify="center"
          >
            <VCard width="510px">
              <VCardTitle class="text-h5 grey lighten-2">
                Please accept the updated terms for cardholders
              </VCardTitle>

              <VCardText style="text-align: start">
                <VRow>
                  <VCol class="mt-4 pb-0 mb-6 align-top">
                    In order to continue using cards issued on Ribbon, you must
                    accept the
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://stripe.com/legal/issuing/celtic-authorized-user-terms"
                      @click.stop
                    >Authorized User Terms</a>
                    for cardholders.
                  </VCol>
                </VRow>
              </VCardText>
              <VCardActions class="justify-center">
                <VBtn
                  color="primary"
                  :loading="loading"
                  :disabled="loading"
                  variant="elevated"
                  @click="onAcceptTerms"
                >
                  I Accept the Authorized User Terms
                </VBtn>
              </VCardActions>
            </VCard>
          </VCol>
          <VSpacer />
        </VRow>
      </VContainer>
    </VDialog>
  </div>
</template>
