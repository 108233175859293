import { useUserStore } from './user'
import axios from '@axios'

export const useBankingCardStore = () => {
  return defineStore('banking-card', {
    state: () => ({
      error: null,
      isFetching: false,
      isFetchingList: false,
      isSaving: false,
      data: {} as any,
      list: [] as any[],
    }),
    actions: {
      getList(charity_uuid = 'charity_uuid') {
        this.isFetchingList = true

        return new Promise((resolve, reject) => {
          axios
            .get(`/charities/${charity_uuid}/banking/cards`)
            .then(({ data }) => {
              this.list = data
              this.isFetchingList = false
              resolve(data)
            })
            .catch(response => {
              this.isFetchingList = false
              this.error = response?.data?.message || response
              reject(response?.data?.messagege || response)
            })
        })
      },
      getPersonalList({ charity_uuid = 'charity_uuid', uuid }) {
        this.isFetchingList = true

        return new Promise((resolve, reject) => {
          axios
            .get(`/charities/${charity_uuid}/banking/cardHolders/${uuid}/cards`)
            .then(({ data }) => {
              this.list = data
              this.isFetchingList = false
              resolve(data)
            })
            .catch(response => {
              this.isFetchingList = false
              this.error = response?.data?.message || response
              reject(response?.data?.message || response)
            })
        })
      },
      get({ charity_uuid = 'charity_uuid', uuid }) {
        this.setFetching(true)

        return new Promise((resolve, reject) => {
          axios
            .get(`/charities/${charity_uuid}/banking/cards/${uuid}`)
            .then(({ data }) => {
              this.data = data
              this.setFetching(false)
              resolve(data)
            })
            .catch(response => {
              this.setFetching(false)
              this.error = response?.data?.message || response
              reject(response?.data?.message || response)
            })
        })
      },
      getPersonalCard({ charity_uuid = 'charity_uuid', ch_uuid, uuid }) {
        this.setFetching(true)

        return new Promise((resolve, reject) => {
          axios
            .get(`/charities/${charity_uuid}/banking/cardHolders/${ch_uuid}/cards/${uuid}`)
            .then(({ data }) => {
              this.data = data
              this.setFetching(false)
              resolve(data)
            })
            .catch(response => {
              this.setFetching(false)
              this.error = response?.data?.message || response
              reject(response?.data?.message || response)
            })
        })
      },
      getAdvancedCardDetails({ charity_uuid = 'charity_uuid', uuid }) {
        // this.setFetching(true)

        return new Promise((resolve, reject) => {
          axios
            .get(`/charities/${charity_uuid}/banking/cards/${uuid}/advanced`)
            .then(({ data }) => {
              // this.setFetching(false)
              resolve(data)
            })
            .catch(response => {
              // this.setFetching(false)
              this.error = response?.data?.message || response
              reject(response?.data?.message || response)
            })
        })
      },
      getAdvancedPersonalCardDetails({ charity_uuid = 'charity_uuid', ch_uuid, uuid }) {
        // this.setFetching(true)

        return new Promise((resolve, reject) => {
          axios
            .get(`/charities/${charity_uuid}/banking/cardHolders/${ch_uuid}/cards/${uuid}/advanced`)
            .then(({ data }) => {
              this.setFetching(false)
              resolve(data)
            })
            .catch(response => {
              this.setFetching(false)
              this.error = response?.data?.message || response
              reject(response?.data?.message || response)
            })
        })
      },
      update({ charity_uuid = 'charity_uuid', uuid, body }) {
        return new Promise((resolve, reject) => {
          axios
            .put(`/charities/${charity_uuid}/banking/cards/${uuid}`, body)
            .then(({ data }) => {
              this.data = data
              resolve(data)
            })
            .catch(response => {
              reject(response?.response?.data?.message || response)
            })
        })
      },
      freeze({ charity_uuid = 'charity_uuid', uuid }) {
        return new Promise((resolve, reject) => {
          axios
            .get(`/charities/${charity_uuid}/banking/cards/${uuid}/inactivate`)
            .then(({ data }) => {
              this.data = data
              resolve(data)
            })
            .catch(response => {
              reject(response?.response?.data?.message || response)
            })
        })
      },
      cancel({ charity_uuid = 'charity_uuid', uuid }) {
        return new Promise((resolve, reject) => {
          axios
            .get(`/charities/${charity_uuid}/banking/cards/${uuid}/cancel`)
            .then(({ data }) => {
              this.data = data
              resolve(data)
            })
            .catch(response => {
              reject(response?.response?.data?.message || response)
            })
        })
      },
      activate({ charity_uuid = 'charity_uuid', uuid }) {
        return new Promise((resolve, reject) => {
          axios
            .get(`/charities/${charity_uuid}/banking/cards/${uuid}/activate`)
            .then(({ data }) => {
              this.data = data
              resolve(data)
            })
            .catch(response => {
              reject(response?.response?.data?.error || response)
            })
        })
      },
      activatePersonalCard({ charity_uuid = 'charity_uuid', ch_uuid, uuid }) {
        return new Promise((resolve, reject) => {
          axios
            .get(`/charities/${charity_uuid}/banking/cardHolders/${ch_uuid}/cards/${uuid}/activate`)
            .then(({ data }) => {
              this.data = data
              resolve(data)
            })
            .catch(response => {
              reject(response?.response?.data?.error || response)
            })
        })
      },
      createCardholder({ charity_uuid = 'charity_uuid', user_uuid }) {
        return new Promise((resolve, reject) => {
          axios
            .post(`/charities/${charity_uuid}/banking/cardHolders`, { user_uuid })
            .then(({ data }) => {
              resolve(data)
            })
            .catch(response => {
              this.error = response?.data?.message || response
              reject(response?.data?.message || response)
            })
        })
      },
      disableCardholder({ sponsor_uuid = 'sponsor_uuid', uuid }) {
        return new Promise((resolve, reject) => {
          axios
            .post(`/sponsors/${sponsor_uuid}/banking/cardHolders/${uuid}/inactivate`)
            .then(({ data }) => {
              this.data = data
              resolve(data)
            })
            .catch(response => {
              this.setFetching(false)
              this.error = response?.data?.message || response
              reject(response?.data?.message || response)
            })
        })
      },
      create({ charity_uuid = 'charity_uuid', uuid, body }) {
        return new Promise((resolve, reject) => {
          axios
            .post(`/charities/${charity_uuid}/banking/cards/cardHolders/${uuid}`, body)
            .then(({ data }) => {
              this.data = data
              resolve(data)
            })
            .catch(response => {
              this.error = response?.data?.message || response
              reject(response?.data?.message || response)
            })
        })
      },
      acceptTerms({ charity_uuid = 'charity_uuid', ch_uuid }) {
        this.setFetching(true)

        return new Promise((resolve, reject) => {
          axios
            .post(`/charities/${charity_uuid}/banking/cardHolders/${ch_uuid}/accept-terms`)
            .then(({ data }) => {
              useUserStore().get()
              this.setFetching(false)
              resolve(data)
            })
            .catch(response => {
              this.setFetching(false)
              this.error = response?.data?.message || response
              reject(response?.data?.message || response)
            })
        })
      },
      setBudget({ charity_uuid = 'charity_uuid', budgets, uuid }) {
        return new Promise((resolve, reject) => {
          axios
            .post(`/charities/${charity_uuid}/banking/cards/${uuid}/budgets`, {
              budgets,
            })
            .then(({ data }) => {
              resolve(data)
            })
            .catch(response => {
              this.error = response?.data?.message || response
              reject(response?.data?.message || response)
            })
        })
      },
      setGrant({ charity_uuid = 'charity_uuid', grants, uuid }) {
        return new Promise((resolve, reject) => {
          axios
            .post(`/charities/${charity_uuid}/banking/cards/${uuid}/grants`, { grants })
            .then(({ data }) => {
              resolve(data)
            })
            .catch(response => {
              this.error = response?.data?.messagegegege || response
              reject(response?.data?.message || response)
            })
        })
      },
      reset() {
        this.data = {
          error: null,
          isFetching: false,
          isSaving: false,
          data: {} as any,
          list: [] as any[],
        }
      },
      setFetching(value: boolean) {
        this.isFetching = value
        if (value === true)
          this.error = null
      },
    },
  })()
}
