<script lang="ts" setup>
import { useIdle } from '@vueuse/core'
import { isUserLoggedIn } from '@/router/utils'
import { useAuthStore } from '@/stores/auth'

const TIME_TO_LOGOUT = 90000
const IDLE_TIME = 480 * 60000 // 480 min, 8 hours

const { idle, reset } = useIdle(IDLE_TIME) // 15 min

const auth = useAuthStore()

const dialog = ref(false)
const time = ref(TIME_TO_LOGOUT)
const interval = ref()

const isIdle = computed(() => idle.value && isUserLoggedIn())

const shouldShowModal = computed(() => (isIdle.value || dialog.value) && import.meta.env.VITE_NODE_ENV === 'production')

const readableTime = computed(() => {
  const minutes = Math.floor(time.value / 60000)
  const seconds = ((time.value % 60000) / 1000).toFixed(0)

  return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`
})

const resetTimer = () => {
  reset()
  dialog.value = false
  clearInterval(interval.value)
  time.value = TIME_TO_LOGOUT
}

const onStillHere = () => {
  resetTimer()
  localStorage.setItem('lastRequestTimestamp', new Date().getTime())
}

const onLogout = () => {
  resetTimer()
  auth.logout()
}

watch(isIdle, newVal => {
  if (newVal) {
    dialog.value = true
    interval.value = setInterval(() => {
      time.value -= 1000
      if (!shouldShowModal.value)
        clearInterval(interval.value)
      if (time.value < 1)
        onLogout()
    }, 1000)
  }
})
</script>

<template>
  <div class="text-center">
    <VDialog
      v-model="shouldShowModal"
      fullscreen
    >
      <VContainer
        fluid
        :style="{
          backdropFilter: 'blur(8px) !important',
          height: '100vh',
          width: '100vw',
        }"
      >
        <VRow
          align="center"
          justify="center"
          class="mx-0"
          style="height: 100vh;"
        >
          <VSpacer />
          <VCol
            align="center"
            justify="center"
          >
            <VCard width="500px">
              <VCardTitle class="text-h5 grey lighten-2">
                Your session is about to end.
              </VCardTitle>

              <VCardText style=" margin-top: 1rem;text-align: start;">
                Due to inactivity, we will log you out in
                {{ readableTime }}.
              </VCardText>
              <VCardActions>
                <VBtn
                  variant="text"
                  @click="onStillHere"
                >
                  I'm still here
                </VBtn>
                <VSpacer />
                <VBtn
                  variant="elevated"
                  color="warning"
                  @click="onLogout"
                >
                  Log out
                </VBtn>
              </VCardActions>
            </VCard>
          </VCol>
          <VSpacer />
        </VRow>
      </VContainer>
    </VDialog>
  </div>
</template>
