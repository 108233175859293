export const analytics = {
  track(event: any, params = {}) {
    try {
      if (window.analytics) {
        params.source = 'web'
        window.analytics.track(event, params)
      }
    }
    catch (e) {
      console.log(e)
    }
  },
  identify(userID: string) {
    try {
      if (window.analytics)
        window.analytics.identify(userID)
    }
    catch (e) {
      console.log(e)
    }
  },
}

export const facebook = {
  boot(code: string) {
    window.fbq('init', code)
  },
  track(event: any, params = {}) {
    window.fbq('track', event, params)
  },
}
