import axios from 'axios'
import _ from 'lodash'
import router from '@/router'
import { useAuthStore } from '@/stores/auth'

const axiosIns = axios.create({
  // You can add your headers here
  // ================================
  baseURL: import.meta.env.VITE_API_URL,
  timeout: 10000,

  // headers: {'X-Custom-Header': 'foobar'}
})

// cancel all pending requests on route change
const pendingRequests = []

if (router?.beforeEach) {
  router.beforeEach((to, from, next) => {
    if (to.path !== from.path) {
      for (const source of pendingRequests)
        source.cancel('Canceled due to route change')

      pendingRequests.length = 0
    }
    next()
  })
}

// // ℹ️ Add request interceptor to send the authorization header on each subsequent request after login
// axiosIns.interceptors.request.use(config => {
//   localStorage.setItem('lastRequestTimestamp', new Date().getTime().toString())

//   const { sponsor_id, charity_id, application_id } = router.currentRoute.value.params

//   if ((
//     config.url?.startsWith('/campaigns')
//     || config.url?.startsWith('/invitations')
//     || config.url?.startsWith('/apply')
//   ) && config.baseURL?.includes('/api'))
//     config.baseURL = config.baseURL.replace('/api', '/')

//   if (config.url?.startsWith('/sponsors') && !config.url.endsWith('/sponsors') && config.baseURL?.includes('/api'))
//     config.baseURL = config.baseURL.replace('/api', '/sponsor/api')

//   if (config.url?.startsWith('/ribbon/') && config.baseURL?.includes('/api')) {
//     config.url = config.url.replace('/ribbon/', '/')
//     config.baseURL = config.baseURL.replace('/api', '/ribbon/api')
//   }

//   if (config.url?.includes('sponsor_uuid') && sponsor_id)
//     config.url = config.url.replace('sponsor_uuid', sponsor_id as string)

//   if (config.url?.includes('charity_uuid') && charity_id)
//     config.url = config.url.replace('charity_uuid', charity_id as string)

//   if (config.url?.includes('application_uuid') && application_id)
//     config.url = config.url.replace('application_uuid', application_id as string)

//   // Retrieve token from localStorage
//   const token = localStorage.getItem('accessToken')

//   // If token is found
//   if (token) {
//     // Get request headers and if headers is undefined assign blank object
//     config.headers = config.headers || {}

//     // Set authorization header
//     // ℹ️ JSON.parse will convert token to string
//     config.headers.Authorization = `Bearer ${token}`
//   }

//   // cancel all pending requests on route change
//   const source = axios.CancelToken.source()

//   config.cancelToken = source.token
//   pendingRequests.push(source)

//   // Return modified config
//   return config
// }, error => {
//   return Promise.reject(error)
// })

axiosIns.interceptors.request.use(
  config => {
    // Log the original config
    // console.log('Original request config:', config)

    // Store the timestamp of the last request
    localStorage.setItem('lastRequestTimestamp', new Date().getTime().toString())

    // Retrieve route parameters
    const { sponsor_id, charity_id, application_id } = router.currentRoute.value.params

    // Function to update baseURL based on certain conditions
    function updateBaseURL() {
      if (
        (config.url?.startsWith('/campaigns')
         || config.url?.startsWith('/invitations')
         || config.url?.startsWith('/apply'))
        && config.baseURL?.includes('/api')
      )
        config.baseURL = config.baseURL.replace('/api', '/')

      if (
        config.url?.startsWith('/sponsors')
        && !config.url.endsWith('/sponsors')
        && config.baseURL?.includes('/api')
      )
        config.baseURL = config.baseURL.replace('/api', '/sponsor/api')

      if (config.url?.startsWith('/ribbon/') && config.baseURL?.includes('/api')) {
        config.url = config.url.replace('/ribbon/', '/')
        config.baseURL = config.baseURL.replace('/api', '/ribbon/api')
      }
    }

    // Update baseURL based on the URL
    updateBaseURL()

    // Function to append UUIDs to the URL
    function appendUUIDsToURL() {
      if (config.url?.includes('sponsor_uuid') && sponsor_id)
        config.url = config.url.replace('sponsor_uuid', sponsor_id as string)

      if (config.url?.includes('charity_uuid') && charity_id)
        config.url = config.url.replace('charity_uuid', charity_id as string)

      if (config.url?.includes('application_uuid') && application_id)
        config.url = config.url.replace('application_uuid', application_id as string)
    }

    // Append UUIDs to the URL
    appendUUIDsToURL()

    // Retrieve and set the authorization token
    const token = localStorage.getItem('accessToken')
    if (token) {
      config.headers = config.headers || {}
      config.headers.Authorization = `Bearer ${token}`
    }

    // Cancel all pending requests on route change
    const source = axios.CancelToken.source()

    config.cancelToken = source.token
    pendingRequests.push(source)

    // Log the modified config
    // console.log('Modified request config:', config)

    return config
  },
  error => {
    // Log errors for debugging
    console.error('Request interceptor error:', error)

    return Promise.reject(error)
  },
)

// ℹ️ Add response interceptor to handle 401 response
axiosIns.interceptors.response.use(response => {
  // cancel all pending requests on route change
  const source = response.config.cancelToken
  if (source) {
    const index = pendingRequests.indexOf(source)
    if (index !== -1)
      pendingRequests.splice(index, 1)
  }

  if (response.data && response.data.data && !response.data.meta)
    response.data = response.data.data

  return Promise.resolve(response)
}, async error => {
  const auth = useAuthStore()
  const status = _.get(error, 'response.status')

  if (axios.isCancel(error)) {
    console.log('Request was cancelled:', error.message)

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    return new Promise(() => {})
  }

  if (import.meta.env.VITE_NODE_ENV === 'staging') {
    console.log('Error status:', error.response ? error.response.status : 'No response')
    console.log('Error message:', error.message)
  }

  if (!error.response) {
    console.log('Please check your internet connection.')

    if (import.meta.env.VITE_NODE_ENV === 'staging')
      console.log('Detailed network error or no response:', error)

    return new Promise(() => null)
  }

  if (status === 401) {
    auth.logout()
  }
  else if (status === 419) {
    // Refresh our session token
    await axios.get('/sanctum/csrf-cookie')

    // Return a new request using the original request's configuration
    return axios(error.response.config)
  }
  else if (status === 403) {
    router.push('/')

    return Promise.reject(error)
  }

  return Promise.reject(error)
})

export default axiosIns
