import * as FullStory from '@fullstory/browser'
import { useCharityStore } from './charity'
import axios from '@axios'
import { get } from '@core/utils'

export const useUserStore = () => {
  const route = useRoute()

  return defineStore('user', {
    state: () => ({
      error: '',
      isFetching: false,
      data: {} as any,
    }),
    getters: {
      access: state => get(state, 'data.access', {}),
      hasRole: state => (roleList: string[]) => get(state, 'data.roles', []).some((role: { name: string }) => roleList.includes(role.name)),
      hasPermission: state => (permissionList: string[]) => get(state, 'data.access', {}).ribbon || get(state, 'data.access', {}).sponsor || get(state, 'data.permissions', []).some((permission: { name: string }) => permissionList.includes(permission.name)),
      currentCardholder: state => get(state, 'data.card_holders', []).find((ch: { charity: { uuid: string } }) => ch.charity.uuid === route.params.charity_id),
      charities: state => get(state, 'data.charities', []),
      sponsors: state => get(state, 'data.sponsors', []),
      applications: state => get(state, 'data.applications', []),
      sponsorOnboarding: state => get(state, 'data.sponsor_onboarding', []),
    },
    actions: {
      async refreshData() {
        this.setFetching(true)
        try {
          const response = await axios.get('/profile', {
            headers: {
              'Cache-Control': 'no-cache',
              'Pragma': 'no-cache',
              'Expires': '0',
            },
          })

          this.data = response.data
          FullStory.identify(this.data.uuid, {
            email: this.data.email,
            displayName: this.data.name,
          })
        }
        catch (error) {
          this.error = error.response?.data?.message || error.message
          this.setFetching(false)
        }
        finally {
          this.setFetching(false)
        }
      },
      get() {
        const charity = useCharityStore()

        this.setFetching(true)

        return new Promise((resolve, reject) => {
          axios
            .get('/profile')
            .then(({ data }) => {
              if (data.charities?.length === 1 && !data.selected_charity)
                charity.set(data.charities[0].uuid)

              this.data = data
              this.setFetching(false)

              FullStory.identify(data.uuid, {
                email: data.email,
                displayName: data.name,
              })

              resolve(data)
            })
            .catch(({ response }) => {
              this.error = response?.data?.message || response
              this.setFetching(false)

              reject(this.error)
            })
        })
      },
      update(data: any) {
        this.setFetching(true)

        return new Promise((resolve, reject) => {
          axios
            .post('/profile/user-information', data)
            .then(r => {
              this.get()
              resolve(r)
            })
            .catch(response => {
              this.error = get(response, response?.data?.message, response)
              this.setFetching(false)
              reject(this.error)
            })
        })
      },
      updatePassword(body: any) {
        this.setFetching(true)

        return new Promise((resolve, reject) => {
          axios
            .post('/profile/password', body)
            .then(r => {
              this.get()
              resolve(r)
            })
            .catch(response => {
              this.error = get(response, response?.data?.message, response)
              this.setFetching(false)
              reject(this.error)
            })
        })
      },
      verifyEmail(code: string) {
        this.setFetching(true)

        return new Promise((resolve, reject) => {
          axios
            .post('/profile/email-verify-change', { code })
            .then(r => {
              this.get()
              resolve(r)
            })
            .catch(response => {
              this.error = get(response, response?.data?.message, response)
              this.setFetching(false)
              reject(this.error)
            })
        })
      },
      updatePhone(phone: string) {
        this.setFetching(true)

        return new Promise((resolve, reject) => {
          axios
            .post('/profile/phone', { phone })
            .then(r => {
              this.get()
              resolve(r)
            })
            .catch(response => {
              this.error = get(response, response?.data?.message, response)
              this.setFetching(false)
              reject(this.error)
            })
        })
      },
      verifyPhone(code: string) {
        return new Promise((resolve, reject) => {
          axios
            .post('/profile/phone-verify-change', { code })
            .then(r => {
              this.get()
              resolve(r)
            })
            .catch(response => {
              this.error = get(response, response?.data?.message, response)
              this.setFetching(false)
              reject(this.error)
            })
        })
      },
      resendPhoneCode() {
        return new Promise((resolve, reject) => {
          axios
            .post('/profile/resend-verification-phone')
            .then(r => {
              resolve(r)
            })
            .catch(response => {
              this.error = get(response, response?.data?.message, response)
              this.setFetching(false)
              reject(this.error)
            })
        })
      },
      resendEmailCode() {
        return new Promise((resolve, reject) => {
          axios
            .post('/profile/resend-verification-email')
            .then(r => {
              resolve(r)
            })
            .catch(response => {
              this.error = get(response, response?.data?.message, response)
              this.setFetching(false)
              reject(this.error)
            })
        })
      },
      setFetching(value: boolean) {
        this.isFetching = value
        if (value === true)
          this.error = null
      },
    },
  })()
}
