<script setup lang="ts">
import { useHead } from '@vueuse/head'
import { useTheme } from 'vuetify'
import { useApplicationStore } from './stores/application'
import { useCharityStore } from './stores/charity'
import { useEntityStore } from './stores/entity'
import { useSponsorStore } from './stores/sponsor'
import { useUserStore } from './stores/user'
import { hexToRgb } from '@layouts/utils'
import { useThemeConfig } from '@core/composable/useThemeConfig'

const { syncInitialLoaderTheme, syncVuetifyThemeWithTheme: syncConfigThemeWithVuetifyTheme, isAppRtl, handleSkinChanges } = useThemeConfig()

const { global } = useTheme()

const route = useRoute()
const router = useRouter()

const user = useUserStore()
const charity = useCharityStore()
const sponsor = useSponsorStore()
const application = useApplicationStore()
const entity = useEntityStore()
const userGuidingReady = ref(false)
const checkingInterval = ref()

// ℹ️ Sync current theme with initial loader theme
syncInitialLoaderTheme()
syncConfigThemeWithVuetifyTheme()
handleSkinChanges()

// Create refs for the shared variables
const isRibbon = ref(localStorage.getItem('isRibbon') === 'true')
const isSponsor = ref(localStorage.getItem('isSponsor') === 'true')

// Function to set the values in localStorage
const setLocalStorage = () => {
  localStorage.setItem('isRibbon', isRibbon.value.toString())
  localStorage.setItem('isSponsor', isSponsor.value.toString())
}

// Watch for changes in isRibbon and isSponsor and update localStorage
watch(isRibbon, setLocalStorage)
watch(isSponsor, setLocalStorage)

// Provide the variables to the entire application
provide('isRibbon', isRibbon)
provide('isSponsor', isSponsor)

// verifyCharityUrlState(route)

onErrorCaptured(e => {
  if (import.meta.env.VITE_APP_ENV === 'local')
    console.error(e)

  return false
})

// Meta Info
const siteData = reactive({
  title: 'Ribbon',
  description: 'Ribbon helps new nonprofits get started in minutes, not months, by leveraging an innovative fiscal sponsorship software.',
})

const useTitleTemplate = (baseTitle: any) => {
  return computed(() => {
    if (!baseTitle.value || baseTitle.value.trim() === '')
      return 'Ribbon'

    return `${baseTitle.value} | Ribbon`
  })
}

// Stop error resizeObserver
const debounce = (callback: (...args: any[]) => void, delay: number) => {
  let tid: any

  return function (...args: any[]) {
    const ctx = self

    tid && clearTimeout(tid)
    tid = setTimeout(() => {
      callback.apply(ctx, args)
    }, delay)
  }
}

const _ = (window as any).ResizeObserver;

(window as any).ResizeObserver = class ResizeObserver extends _ {
  constructor(callback: (...args: any[]) => void) {
    callback = debounce (callback, 20)
    super(callback)
  }
}

// Create a ref to hold the fullTitle
const currentFullTitle = ref(useTitleTemplate(siteData.title).value)

watch(() => route.meta.title, newTitle => {
  currentFullTitle.value = useTitleTemplate(ref(newTitle)).value
})

watch(() => userGuidingReady.value, data => {
  if (data) {
    clearInterval(checkingInterval.value)
    window.userGuiding.identify(`ribbon_${user.data.uuid}`)
  }
})

useHead({
  title: () => currentFullTitle.value,
  meta: [
    {
      name: 'description',
      content: computed(() => siteData.description),
    },
  ],
})

onMounted(() => {
  if (
    route.meta.resource !== 'Public'
      && localStorage.getItem('accessToken')
  ) {
    user.get().then(() => {
      checkingInterval.value = setInterval(() => {
        if (window.userGuiding)
          userGuidingReady.value = true
      }, 500)
    })

    router.isReady().then(() => {
      if (route.params.charity_id)
        charity.get(route.params.charity_id as string)

      if (route.params.sponsor_id)
        sponsor.get(route.params.sponsor_id as string)

      if (route.params.application_id && !route.params.sponsor_id)
        application.get(route.params.application_id as string)

      if (!route.path)
        return null

      if (route.path && route.path.startsWith('/ribbon/'))
        entity.set({ ribbon: true })
      else if (route.path.startsWith('/sponsor/'))
        entity.set({ uuid: route.params.sponsor_id })
      else if (route.path.startsWith('/application/'))
        entity.set({ uuid: route.params.application_id })
      else if (route.path.startsWith('/charity/'))
        entity.set({ uuid: route.params.charity_id })
      else if (route.path && route.path.includes('char'))
        entity.set({ uuid: route.params.charity_id })
    })
  }
})
</script>

<template>
  <VLocaleProvider :rtl="isAppRtl">
    <!-- ℹ️ This is required to set the background color of active nav link based on currently active global theme's primary -->
    <VApp :style="`--v-global-theme-primary: ${hexToRgb(global.current.value.colors.primary)}`">
      <RouterView />
      <InactivityModal />
      <TermsModal v-if="route.params.charity_id" />
      <ScrollToTop />
    </VApp>
  </VLocaleProvider>
</template>
