const donors = [
  {
    path: '/charity/:charity_id/donors',
    name: 'donors',
    component: () => import('@/pages/donors/index.vue'),
    meta: {
      title: 'Donors',
      resource: 'charity',
      permissions: ['view_donors'],
    },
    props: true,
  },
  {
    path: '/charity/:charity_id/donor/details/:uuid',
    name: 'donor-details',
    component: () => import('@/pages/donors/details.vue'),
    meta: {
      title: 'Donor Details',
      resource: 'charity',
      permissions: ['view_donors'],
    },
    props: true,
  },
  {
    path: '/sponsor/:sponsor_id/donor/details/:uuid',
    name: 'donor-details-sponsor',
    component: () => import('@/pages/donors/details.vue'),
    meta: {
      title: 'Donor Details',
      resource: 'sponsor',
      permissions: ['view_donors'],
    },
    props: true,
  },
]

export default donors
