const donations = [
  {
    path: '/charity/:charity_id/donations',
    name: 'donations',
    component: () => import('@/pages/donations.vue'),
    meta: {
      title: 'Donations',
      resource: 'charity',
      permissions: ['view_donations'],
    },
    props: true,
  },
]

export default donations
