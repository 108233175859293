import axios from '@axios'

export const useSponsorStore = () => {
  return defineStore('sponsor', {
    state: () => ({
      error: '',
      isFetching: false,
      data: {} as any,
      list: [] as any[],
      link: '',
    }),
    actions: {
      getList(creds?: any) {
        this.setFetching(true)

        return new Promise((resolve, reject) => {
          axios
            .get('/sponsors', creds)
            .then(({ data }) => {
              this.list = data
              this.setFetching(false)
              resolve(data)
            })
            .catch(response => {
              this.setFetching(false)
              this.error = response?.data?.message || response
              reject(response?.data?.message || response)
            })
        })
      },
      get(sponsor_uuid = 'sponsor_uuid') {
        this.setFetching(true)

        return new Promise((resolve, reject) => {
          axios
            .get(`/sponsors/${sponsor_uuid}`)
            .then(({ data }) => {
              this.data = data
              this.setFetching(false)
              resolve(data)
            })
            .catch(({ response }) => {
              this.setFetching(false)
              this.error = response?.data?.message || response
              reject(response?.data?.message || response)
            })
        })
      },
      publicGet(sponsor_uuid = 'sponsor_uuid') {
        this.setFetching(true)

        return new Promise((resolve, reject) => {
          axios
            .get(
              `/sponsors/public/${sponsor_uuid}`,
            )
            .then(({ data }) => {
              this.data = data
              this.setFetching(false)
              resolve(data)
            })
            .catch(({ response }) => {
              this.setFetching(false)
              this.error = response?.data?.message || response
              reject(response?.data?.message || response)
            })
        })
      },
      updateSettings({ uuid = 'sponsor_uuid', data }: { uuid?: string; data: any }) {
        this.setFetching(true)

        const postData = { ...data }
        const legalDocKeys = ['w9_url', 'tax_letter_url', '990_url', 'audit_url']

        legalDocKeys.forEach(key => {
          delete postData[key]
        })

        return new Promise((resolve, reject) => {
          axios
            .put(`/sponsors/${uuid}/settings`, postData)
            .then((r: any) => {
              this.data = r.data
              this.setFetching(false)
              this.get(uuid)
              resolve(r.data)
            })
            .catch(({ response }) => {
              this.setFetching(false)
              this.error = response?.data?.message || response
              reject(response?.data?.message || response)
            })
        })
      },
      updateSpendingControls({ uuid = 'sponsor_uuid', data }: { uuid?: string; data: any }) {
        this.setFetching(true)

        return new Promise((resolve, reject) => {
          axios
            .put(`/sponsors/${uuid}/spending-controls`, data)
            .then(({ data }) => {
              this.setFetching(false)
              this.get(uuid)
              resolve(data)
            })
            .catch(({ response }) => {
              this.setFetching(false)
              this.error = response?.data?.message || response
              reject(response?.data?.message || response)
            })
        })
      },
      uploadLogo({ sponsor_uuid = 'sponsor_uuid', logo }: { sponsor_uuid?: string; logo: any }) {
        this.setFetching(true)

        const bodyFormData = new FormData()

        if ('main_photo' in logo)
          bodyFormData.append('main_photo', logo.main_photo)
        else
          bodyFormData.append('logo', logo)

        return new Promise((resolve, reject) => {
          axios
            .post(`/sponsors/${sponsor_uuid}/logos`, bodyFormData, {
              headers: {
                'content-type': 'multipart/form-data',
              },
            })
            .then(data => {
              this.setFetching(false)
              this.get(sponsor_uuid)
              resolve(data)
            })
            .catch(({ response }) => {
              if (response.status === 413) {
                this.error = 'File too big'
                reject(new Error('Your file(s) are too big. The maximum file size is 7.5mb. Please try again.'))
              }
              else {
                this.error = response?.data?.message || response
                reject(response?.data?.message || response)
              }
              this.setFetching(false)
            })
        })
      },
      removeLogo({ sponsor_uuid = 'sponsor_uuid', data }: { sponsor_uuid?: string; data: any }) {
        this.setFetching(true)

        return new Promise((resolve, reject) => {
          axios
            .post(`/sponsors/${sponsor_uuid}/removeLogo`, data)
            .then(r => {
              this.setFetching(false)
              this.get(sponsor_uuid)
              resolve(r)
            })
            .catch(({ response }) => {
              this.error = response?.data?.message || response
              reject(response?.data?.message || response)

              this.setFetching(false)
            })
        })
      },
      getDocumentList(sponsor_uuid = 'sponsor_uuid') {
        this.setFetching(true)

        return new Promise((resolve, reject) => {
          axios
            .get(`/sponsors/${sponsor_uuid}/documents`)
            .then(({ data }) => {
              this.list = data
              this.setFetching(false)
              resolve(data)
            })
            .catch(response => {
              this.setFetching(false)
              this.error = response?.data?.message || response
              reject(response?.data?.message || response)
            })
        })
      },
      createLink(sponsor_uuid = 'sponsor_uuid') {
        this.setFetching(true)

        return new Promise((resolve, reject) => {
          axios
            .post(`/sponsors/${sponsor_uuid}/link`)
            .then(({ data }) => {
              this.link = data.link
              this.setFetching(false)
              resolve(data)
            })
            .catch(({ response }) => {
              this.setFetching(false)
              this.error = response?.data?.message || response
              reject(response?.data?.message || response)
            })
        })
      },
      sponsorsUpdate({ sponsor_uuid = 'sponsor_uuid', donor_uuid, form }) {
        this.setFetching(true)

        return new Promise((resolve, reject) => {
          axios
            .put(`/sponsors/${sponsor_uuid}/donors/${donor_uuid}/sponsors-update-donor`, form, {
              headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
              },
            })
            .then(({ data }) => {
              this.data = data
              this.setFetching(false)
              resolve(data)
            })
            .catch(({ response }) => {
              this.setFetching(false)
              this.error = response?.data?.message || response
              reject(response?.data?.message || response)
            })
        })
      },
      updateDonorSettings({ sponsor_uuid = 'sponsor_uuid', formData }) {
        this.setFetching(true)

        return new Promise((resolve, reject) => {
          axios
            .put(`/sponsors/${sponsor_uuid}/donor-settings`, formData)
            .then(({ data }) => {
              this.data = data
              this.setFetching(false)
              resolve(data)
            })
            .catch(({ response }) => {
              this.setFetching(false)
              this.error = response?.data?.message || response
              reject(response?.data?.message || response)
            })
        })
      },
      setFetching(value: boolean) {
        this.isFetching = value
        if (value === true)
          this.error = ''
      },
    },
  })()
}
