const giving = [
  {
    path: '/charity/:charity_id/giving/forms',
    name: 'giving-forms',
    component: () => import('@/pages/giving/forms/index.vue'),
    meta: {
      title: 'Forms',
      resource: 'charity',
      permissions: ['view_giving_forms'],
    },
    props: true,
  },
  {
    path: '/charity/:charity_id/giving/forms/:uuid',
    name: 'giving-forms-edit',
    component: () => import('@/pages/giving/forms/createOrEdit.vue'),
    meta: {
      resource: 'charity',
      permissions: ['view_giving_forms'],
    },
    props: true,
  },
  {
    path: '/charity/:charity_id/giving/campaigns',
    name: 'giving-campaigns',
    component: () => import('@/pages/giving/campaigns/index.vue'),
    meta: {
      title: 'Campaigns',
      resource: 'charity',
      permissions: ['view_giving_campaigns'],
    },
    props: true,
  },
  {
    path: '/charity/:charity_id/giving/campaigns/:uuid',
    name: 'giving-campaigns-edit',
    component: () => import('@/pages/giving/campaigns/createOrEdit.vue'),
    meta: {
      resource: 'charity',
      permissions: ['view_giving_campaigns'],
    },
    props: true,
  },
]

export default giving
