const givingPublic = [
  {
    path: '/campaigns/:campaign_uuid',
    name: 'giving-public-campaigns',
    component: () => import('@/pages/giving/public/campaign.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
    },
    props: true,
  },
  {
    path: '/cancel-subscription/:customer_uuid/:account_uuid',
    name: 'donateto-campaigns-donor',
    component: () => import('@/pages/giving/public/donor.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
    },
    props: true,
  },
]

export default givingPublic
