const settings = [
  {
    path: '/settings/profile',
    name: 'profile-settings',
    component: () => import('@/pages/settings/Profile.vue'),
    meta: {
      title: 'Profile',
    },
  },
  {
    path: '/charity/:charity_id/settings',
    name: 'charity-settings',
    component: () =>
      import('@/pages/settings/account-settings/AccountSettings.vue'),
    meta: {
      title: 'Account Settings',
      private: true,
    },
  },
  {
    path: '/charity/:charity_id/sponsor/:sponsor_id',
    name: 'charity-sponsor-profile-view',
    component: () => import('@/pages/sponsor/ViewSponsor.vue'),
    meta: {
    // layout: 'onboarding',
      step: null,
      private: true,
    },
    props: true,
  },
  {
    path: '/sponsor/:sponsor_id/settings',
    name: 'sponsor-settings',
    component: () =>
      import('@/pages/settings/sponsor-settings/AccountSettings.vue'),
    meta: {
      title: 'Sponsor Settings',
      private: true,
    },
  },
  {
    path: '/sponsor/:sponsor_id/settings/contract/create',
    name: 'sponsor-settings-contract-create',
    component: () =>
      import('@/pages/settings/sponsor-settings/CreateContract.vue'),
    meta: {
      title: 'Sponsor Settings - Create Contract',
      private: true,
    },
  },
  {
    path: '/sponsor/:sponsor_id/profile',
    name: 'sponsor-profile-preview',
    component: () => import('@/pages/sponsor/ViewSponsor.vue'),
    meta: {
      // layout: 'onboarding',
      step: null,
      private: true,
    },
    props: true,
  },
]

export default settings
