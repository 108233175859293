export const Skins = {
  Default: 'default',
  Bordered: 'bordered',
} as const

export const RouteTransitions = {
  Fade: 'app-transition-fade',

  // 'Zoom Fade': 'app-transition-zoom-fade',
  // 'Fade Bottom': 'app-transition-fade-bottom',
  // 'Slide Fade': 'app-transition-slide-fade',
  // 'Zoom out': 'app-transition-zoom-out',

} as const
