import { useUserStore } from './user'
import axios from '@axios'
import { get } from '@core/utils'

export const useCharityStore = () => {
  return defineStore('charity', {
    state: () => ({
      error: '',
      isFetching: false,
      data: {} as any,
      list: [] as any[],
      paymentSession: '',
    }),
    getters: {
      sponsor: state => get(state, 'charity.sponsor', {}),
    },
    actions: {
      getList(creds?: any) {
        this.setFetching(true)

        return new Promise((resolve, reject) => {
          axios
            .get('/charities', creds)
            .then(({ data }) => {
              this.list = data
              this.setFetching(false)
              resolve(data)
            })
            .catch(response => {
              this.setFetching(false)
              this.error = get(response, response?.data?.message, response)
              reject(this.error)
            })
        })
      },
      get(uuid = 'charity_uuid') {
        this.setFetching(true)

        return new Promise((resolve, reject) => {
          axios
            .get(`/charities/${uuid}`)
            .then(({ data }) => {
              this.data = data
              this.setFetching(false)
              resolve(data)
            })
            .catch(({ response }) => {
              this.setFetching(false)
              this.error = get(response, response?.data?.message, response)
              reject(this.error)
            })
        })
      },
      set(uuid = 'charity_uuid') {
        const user = useUserStore()

        this.setFetching(true)

        return new Promise((resolve, reject) => {
          axios
            .get(`/charities/${uuid}/set`)
            .then(({ data }) => {
              this.setFetching(false)
              user.get()
              this.get(uuid)
              resolve(data)
            })
            .catch(({ response }) => {
              this.setFetching(false)
              this.error = get(response, response?.data?.message, response)
              reject(this.error)
            })
        })
      },
      updateSettings({ uuid = 'charity_uuid', data }: { uuid?: string; data: any }) {
        this.setFetching(true)

        return new Promise((resolve, reject) => {
          axios
            .put(`/charities/${uuid}/settings`, data)
            .then(r => {
              this.setFetching(false)
              this.get(uuid)
              resolve(r)
            })
            .catch(response => {
              this.setFetching(false)
              this.error = get(response, response?.data?.message, response)
              reject(this.error)
            })
        })
      },
      staff(uuid = 'charity_uuid') {
        this.setFetching(true)

        return new Promise((resolve, reject) => {
          axios
            .get(`/charities/${uuid}/set`)
            .then(() => { })
            .catch(({ response }) => {
              this.setFetching(false)
              this.error = get(response, response?.data?.message, response)
              reject(this.error)
            })
        })
      },
      uploadLogo({ uuid = 'charity_uuid', data }: { uuid?: string; data: any }) {
        this.setFetching(true)

        const bodyFormData = new FormData()
        if (data.logo)
          bodyFormData.append('logo', data.logo)

        if (data.horizontal_logo)
          bodyFormData.append('horizontal_logo', data.horizontal_logo)

        return new Promise((resolve, reject) => {
          axios
            .post(`/charities/${uuid}/logos`, bodyFormData, {
              headers: {
                'content-type': 'multipart/form-data',
              },
            })
            .then(r => {
              this.setFetching(false)
              this.get(uuid)
              resolve(r)
            })
            .catch(response => {
              this.setFetching(false)
              this.error = get(response, response?.data?.message, response)
              reject(this.error)
            })
        })
      },
      removeLogo({ uuid = 'charity_uuid', data }: { uuid?: string; data: any }) {
        this.setFetching(true)

        return new Promise((resolve, reject) => {
          axios
            .post(`/charities/${uuid}/removeLogo`, data)
            .then(r => {
              this.setFetching(false)
              this.get(uuid)
              resolve(r)
            })
            .catch(response => {
              this.setFetching(false)
              this.error = get(response, response?.data?.message, response)
              reject(this.error)
            })
        })
      },
      setPaymentSession(id: string) {
        this.paymentSession = id
      },
      setFetching(value: boolean) {
        this.isFetching = value
        if (value === true)
          this.error = null
      },
    },

  })()
}
