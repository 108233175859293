const banking = [
  {
    path: '/charity/:charity_id/banking',
    name: 'banking-overview',
    component: () => import('@/pages/banking/index.vue'),
    props: true,
    meta: {
      title: 'Banking',
    },
  },
  {
    path: '/charity/:charity_id/banking/cards',
    name: 'banking-card',
    component: () => import('@/pages/banking/cards.vue'),
    props: true,
    meta: {
      title: 'Cards',
    },
  },
  {
    path: '/charity/:charity_id/banking/billpay',
    name: 'banking-bill-pay',
    component: () => import('@/pages/banking/billpay/bills.vue'),
    meta: {
      title: 'Bill Pay',
      permissions: ['view_banking_bill_pay'],
    },
    props: true,
  },
  {
    path: '/charity/:charity_id/banking/reimbursement',
    name: 'banking-expense-reimbursement',
    component: () => import('@/pages/banking/reimbursement.vue'),
    props: true,
    meta: {
      title: 'Reimbursements',
    },
  },
  {
    path: '/charity/:charity_id/banking/reports/category',
    name: 'banking-financial-reports-category',
    component: () => import('@/pages/banking/reports/category.vue'),
    meta: {
      title: 'Spend Report',
      permissions: ['view_banking_spend_report'],
    },
    props: true,
  },
  {
    path: '/charity/:charity_id/banking/reports/statements',
    name: 'banking-financial-reports-statements',
    component: () => import('@/pages/banking/reports/statements.vue'),
    meta: {
      title: 'Statements',
      permissions: ['view_banking_statements'],
    },
    props: true,
  },
]

export default banking
