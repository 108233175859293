import axios from '@axios'

export const useApplicationStore = () => {
  const route = useRoute()

  return defineStore('application', {
    state: () => ({
      error: null,
      isFetching: false,
      data: {} as any,
      list: [] as any[],
    }),
    actions: {
      getList() {
        this.setFetching(true)

        return new Promise((resolve, reject) => {
          axios
            .get('/applications')
            .then(({ data }) => {
              this.list = data
              this.setFetching(false)
              resolve(data)
            })
            .catch(({ response }) => {
              this.setFetching(false)
              this.error = response?.data?.message || response
              reject(response?.data?.message || response)
            })
        })
      },
      get(application_uuid = 'application_uuid') {
        this.setFetching(true)

        return new Promise((resolve, reject) => {
          axios
            .get(`/applications/${application_uuid}`)
            .then(({ data }) => {
              this.setData(data)
              this.setFetching(false)
              resolve(data)
            })
            .catch(({ response }) => {
              this.setFetching(false)
              this.error = response?.data?.message || response
              reject(response?.data?.message || response)
            })
        })
      },
      create({ body }: { body: any }) {
        this.setFetching(true)

        return new Promise((resolve, reject) => {
          axios
            .post('/applications', body)
            .then(({ data }) => {
              this.setFetching(false)
              resolve(data)
            })
            .catch(({ response }) => {
              this.setFetching(false)
              this.error = response?.data?.message || response
              reject(response?.data?.message || response)
            })
        })
      },
      update({ application_uuid = 'application_uuid', body }: { application_uuid?: string; body: any }) {
        this.setFetching(true)

        if (
          body.estimated_fundraising_sources
          && typeof body.estimated_fundraising_sources === 'object'
        ) {
          body.estimated_fundraising_sources = JSON.stringify(
            body.estimated_fundraising_sources,
          )
        }

        if (route.params.application_id)
          application_uuid = route.params.application_id as string

        return new Promise((resolve, reject) => {
          axios
            .post(`/applications/${application_uuid}`, body)
            .then(({ data }) => {
              this.setFetching(false)
              resolve(data)
            })
            .catch(({ response }) => {
              this.setFetching(false)
              this.error = response?.data?.message || response
              reject(response?.data?.message || response)
            })
        })
      },
      fileUpload({ application_uuid = 'application_uuid', estimated_budget }: { application_uuid?: string; estimated_budget: string }) {
        const bodyFormData = new FormData()

        bodyFormData.append('estimated_budget', estimated_budget)

        if (route.params.application_id)
          application_uuid = route.params.application_id as string

        return new Promise((resolve, reject) => {
          axios
            .post(`/applications/${application_uuid}/fileUpload`, bodyFormData, {
              headers: {
                'content-type': 'multipart/form-data',
              },
            })
            .then(({ data }) => {
              this.data.estimated_budget_filename = data.estimated_budget_filename
              this.data.estimated_budget_url = data.estimated_budget_url
              resolve(data)
            })
            .catch(({ response }) => {
              this.error = response?.data?.message || response
              reject(response?.data?.message || response)
            })
        })
      },
      removeFileUpload(application_uuid = 'application_uuid') {
        if (route.params.application_id)
          application_uuid = route.params.application_id as string

        return new Promise((resolve, reject) => {
          axios
            .delete(`/applications/${application_uuid}/fileUpload`)
            .then(({ data }) => {
              this.data.estimated_budget_filename = data.estimated_budget_filename
              this.data.estimated_budget_url = data.estimated_budget_url
              resolve(data)
            })
            .catch(response => {
              this.error = response?.data?.message || response
              reject(response?.data?.message || response)
            })
        })
      },
      submit({ application_uuid = 'application_uuid', body }: { application_uuid?: string; body: any }) {
        this.setFetching(true)

        if (route.params.application_id)
          application_uuid = route.params.application_id as string

        return new Promise((resolve, reject) => {
          axios
            .post(`/applications/${application_uuid}/submit`, body)
            .then(({ data }) => {
              this.setFetching(false)
              resolve(data)
            })
            .catch(({ response }) => {
              this.setFetching(false)
              this.error = response?.data?.message || response
              reject(response?.data?.message || response)
            })
        })
      },
      withdraw(application_uuid = 'application_uuid') {
        this.setFetching(true)

        if (route.params.application_id)
          application_uuid = route.params.application_id as string

        return new Promise((resolve, reject) => {
          axios
            .post(`/applications/${application_uuid}/withdrawn`)
            .then(({ data }) => {
              this.setFetching(false)
              resolve(data)
            })
            .catch(({ response }) => {
              this.setFetching(false)
              this.error = response?.data?.message || response
              reject(response?.data?.message || response)
            })
        })
      },
      setFetching(value: boolean) {
        this.isFetching = value
        if (value === true)
          this.error = null
      },
      setData(data: any) {
        const fundingSources = JSON.parse(data.estimated_fundraising_sources) || []

        let mission_statement = data.mission_statement
        if (mission_statement)
          mission_statement = mission_statement.replace(/<br\s*[\/]?>/gi, '\n')

        let vision = data.vision
        if (vision)
          vision = vision.replace(/<br\s*[\/]?>/gi, '\n')

        let estimated_funding_details = data.estimated_funding_details
        if (estimated_funding_details) {
          estimated_funding_details = estimated_funding_details.replace(
            /<br\s*[\/]?>/gi,
            '\n',
          )
        }

        let estimated_expense_details = data.estimated_expense_details
        if (estimated_expense_details) {
          estimated_expense_details = estimated_expense_details.replace(
            /<br\s*[\/]?>/gi,
            '\n',
          )
        }

        this.data = {
          ...data,
          mission_statement,
          vision,
          estimated_expense_details,
          estimated_funding_details,
          estimated_fundraising_sources: fundingSources,
        }

        this.setFetching(false)
      },
    },
  })()
}
